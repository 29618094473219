import {useEffect, useState} from 'react';

export const usePrefersReducedMotion = () => {
	const [hasReducedMotionPreference, setHasReducedMotionPreference] = useState(false);

	useEffect(() => {
		const mediaQueryList = window.matchMedia('(prefers-reduced-motion: reduce)');
		const initialPreference = mediaQueryList.matches;
		setHasReducedMotionPreference(initialPreference);

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const listener = (event: any) => {
			setHasReducedMotionPreference(event.matches);
		};
		mediaQueryList.addEventListener('change', listener);
		return () => {
			mediaQueryList.removeEventListener('change', listener);
		};
	}, []);

	return hasReducedMotionPreference;
};
