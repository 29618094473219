import imageUrlBuilder from '@sanity/image-url';
import type {SanityImage} from '@selvklart/sanity-next-image';

import type {Image, ImageWeb} from '@/modules/foundation/components/image/types';
import type {ImageWebSchema} from '@/modules/foundation/sanity/image/schema';
import {dataset, projectId} from '@/sanity/lib/api';

export const imageFromSanityImage = (image: SanityImage, aspectRatio?: number): Image => {
	// FIXME: This funciton should move into @selvklart/sanity-next-image
	// First find the natural width and height from the asset ID. This
	// seems a bit hacky, but it's the same thing next-sanity-image
	// does, so it's likely to be the correct way of doing it. If we
	// couldn't parse the size form the ID we would have to do another
	// request to fetch the asset document, which would be annoying.
	const dimensions = image.asset._ref.split('-')[2];
	let [width, height] = dimensions.split('x').map((num) => parseInt(num, 10));

	// If the image has been cropped, adjust the width and height to
	// the cropped size:
	const crop = image.crop;
	if (crop) {
		width = Math.round(width * (1 - (crop.left + crop.right)));
		height = Math.round(height * (1 - (crop.top + crop.bottom)));
	}

	// Resolve the hotspot of the image, taking into consideration the
	// crop rectangle if one is set:
	let hotspot: {x: number; y: number} | undefined;
	if (image.hotspot) {
		if (image.crop) {
			hotspot = {
				x: (image.hotspot.x - image.crop.left) / (1 - image.crop.left - image.crop.right),
				y: (image.hotspot.y - image.crop.top) / (1 - image.crop.top - image.crop.bottom),
			};
		} else {
			hotspot = {
				x: image.hotspot.x,
				y: image.hotspot.y,
			};
		}
	}

	// If the user asked for a different aspect ratio, shrink either
	// the height or width depending on whether the image should be
	// wider or more narrow than it naturally is:
	if (aspectRatio) {
		const naturalAspectRatio = width / height;

		if (aspectRatio > naturalAspectRatio) {
			height = Math.round(width / aspectRatio);
		} else {
			width = Math.round(height * aspectRatio);
		}
	}

	const src = imageUrlBuilder({
		dataset: dataset,
		projectId: projectId,
	})
		.image(image)
		.width(width)
		.height(height)
		.url();

	return {
		src,
		width,
		height,
		hotspot,
	};
};

export const imageWebFromImageWebSchema = (
	image: ImageWebSchema,
	aspectRatio?: number,
): ImageWeb => {
	const img = imageFromSanityImage(image as SanityImage, aspectRatio);

	return {
		image: img,
		altText: image.altText,
		credit: image.credit,
		caption: image.caption,
	};
};
