import type {ReactNode} from 'react';

import {PortableLink} from '@/modules/foundation/components/portable-text/portable-link';

import type {LinkFragment} from '../../link/query';
import {linkFragmentToHref} from '../../link/query';

type Props = {
	children: ReactNode;
	value: LinkFragment;
};

export const PortableLinkContainer = ({children, value}: Props) => {
	return (
		<PortableLink href={linkFragmentToHref(value)} targetBlank={value?.targetBlank}>
			{children}
		</PortableLink>
	);
};
