import type {ReactNode} from 'react';

import {Paragraph} from '@/modules/foundation/components/typography/paragraph';
import {cn} from '@/modules/foundation/shared/tailwind';

type Props = {
	children: ReactNode;
	className?: string;
};

export const Normal = ({children, className}: Props) => {
	return <Paragraph className={cn(['mb-6', 'last:mb-0', className])}>{children}</Paragraph>;
};
