import clsx from 'clsx';

import {VideoPlayer} from '@/modules/foundation/components/video';

type Props = {
	id: string;
	title: string;
};

export const Video = ({id, title}: Props) => {
	return (
		<div className={clsx('mb-4', 'rounded-[3px]', 'overflow-hidden')}>
			<VideoPlayer videoId={id} title={title} />
		</div>
	);
};
