/* eslint-disable react-perf/jsx-no-new-object-as-prop */

import type {FC} from 'react';
import type {PortableTextComponentProps, PortableTextMarkComponentProps} from '@portabletext/react';
import {PortableText as PortableTextReact} from '@portabletext/react';
import type {PortableTextBlock} from '@portabletext/types';
import type {SetOptional} from 'type-fest';

import {Blockquote} from '@/modules/foundation/components/portable-text/blockquote';
import {BulletList} from '@/modules/foundation/components/portable-text/bullet-list';
import {
	Heading2,
	Heading3,
	Heading4,
	Heading5,
	Heading6,
} from '@/modules/foundation/components/portable-text/headings';
import {Normal} from '@/modules/foundation/components/portable-text/normal';
import {NumberList} from '@/modules/foundation/components/portable-text/number-list';
import {PortableImage} from '@/modules/foundation/components/portable-text/portable-image';
import {Video} from '@/modules/foundation/components/portable-text/video';
import {imageWebFromImageWebSchema} from '@/modules/foundation/sanity/image/query';
import type {ImageWebSchema} from '@/modules/foundation/sanity/image/schema';
import type {LinkFragment} from '@/modules/foundation/sanity/link/query';
import {cn} from '@/modules/foundation/shared/tailwind';

import {CallToActionLineContainer} from './call-to-action-line-container/CallToActionLine';
import type {CallToActionLineFragment} from './call-to-action-line-container/query';
import type {VideoSchema} from './video/schema';
import {PortableLinkContainer} from './portable-link-container';

/**
 * Override class names for portable text components
 */
export type PortableTextOverrides = {
	p?: string;
	h2?: string;
	h3?: string;
	h4?: string;
	h5?: string;
	h6?: string;
	ul?: string;
	ol?: string;
	callToActionLine?: string;
};

interface Props {
	blocks: SetOptional<PortableTextBlock, 'children'>[];
	overrideClassNames?: PortableTextOverrides;
}

export const PortableText: FC<Props> = ({blocks, overrideClassNames}) => {
	return (
		<PortableTextReact
			value={blocks}
			components={{
				block: {
					normal: ({children}) => (
						<Normal className={overrideClassNames?.p}>{children}</Normal>
					),
					blockquote: ({children}) => <Blockquote>{children}</Blockquote>,
					h2: ({children}) => (
						<Heading2 className={cn([overrideClassNames?.h2])}>{children}</Heading2>
					),
					h3: ({children}) => (
						<Heading3 className={cn([overrideClassNames?.h3])}>{children}</Heading3>
					),
					h4: ({children}) => (
						<Heading4 className={cn([overrideClassNames?.h4])}>{children}</Heading4>
					),
					h5: ({children}) => (
						<Heading5 className={cn([overrideClassNames?.h5])}>{children}</Heading5>
					),
					h6: ({children}) => (
						<Heading6 className={cn([overrideClassNames?.h6])}>{children}</Heading6>
					),
				},
				marks: {
					link: ({value, children}: PortableTextMarkComponentProps<LinkFragment>) => {
						if (!value) return null;
						return (
							<PortableLinkContainer value={value}>{children}</PortableLinkContainer>
						);
					},
					linkAnnotation: () => null,
				},
				list: {
					bullet: ({children}) => (
						<BulletList className={overrideClassNames?.ul}>{children}</BulletList>
					),
					number: ({children}) => (
						<NumberList className={overrideClassNames?.ol}>{children}</NumberList>
					),
				},
				types: {
					callToActionLine: ({
						value,
					}: PortableTextComponentProps<CallToActionLineFragment>) => (
						<CallToActionLineContainer
							value={value}
							size={value.size}
							className={overrideClassNames?.callToActionLine}
						/>
					),
					image: ({value}: PortableTextComponentProps<ImageWebSchema>) => {
						return <PortableImage image={imageWebFromImageWebSchema(value)} />;
					},
					video: ({value}: PortableTextComponentProps<VideoSchema>) => {
						if (value.id && value.id.length > 0 && value.title) {
							return <Video id={value.id} title={value.title} />;
						}
					},
				},
			}}
		/>
	);
};
