import type {FC} from 'react';
import Link from 'next/link';

import type {ButtonProps} from '@/modules/foundation/components/button';
import {Button} from '@/modules/foundation/components/button';

export type CallToActionProps = {
	href: string;
	targetBlank?: boolean;
	text: string;
	color?: ButtonProps['intent'];
	size?: ButtonProps['size'];
};

export const CallToAction: FC<CallToActionProps> = ({href, targetBlank, text, color, size}) => {
	return (
		<Button asChild size={size} intent={color}>
			<Link href={href} {...(targetBlank && {target: '_blank'})}>
				{text}
			</Link>
		</Button>
	);
};
