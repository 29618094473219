import type {PropsWithChildren} from 'react';

import {Link} from '@/modules/foundation/components/link';

type Props = PropsWithChildren & {
	href?: string;
	targetBlank?: boolean;
};

export const PortableLink = ({children, href, targetBlank}: Props) => {
	return (
		<Link href={href} targetBlank={targetBlank}>
			{children}
		</Link>
	);
};
