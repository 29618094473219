import type {ReactNode} from 'react';

import {cn} from '@/modules/foundation/shared/tailwind';

type Props = {
	children: ReactNode;
	className?: string;
};

export const NumberList = ({children, className}: Props) => {
	return (
		<ol
			className={cn(['my-8', 'space-y-4', 'list-decimal', 'ml-8', 'text-inherit', className])}
		>
			{children}
		</ol>
	);
};
