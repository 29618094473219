import React from 'react';

interface Props {
	width?: string;
	height?: string;
	color?: string;
	className?: string;
}

export const Play = ({width = '16', height = '18', color = 'white', className}: Props) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 16 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className ?? ''}
		>
			<path
				d="M14.189 7.3548C15.5223 8.12461 15.5223 10.0491 14.189 10.8189L3.53524 16.9698C2.20191 17.7396 0.535241 16.7774 0.535241 15.2378L0.535242 2.93593C0.535242 1.39633 2.20191 0.434075 3.53524 1.20388L14.189 7.3548Z"
				fill={color}
			/>
		</svg>
	);
};
